import axios from 'axios'
import { transQuery } from './util'
import { Toast } from 'vant'

/* 请求封装 */
const ajaxGateWay = (opt) => {
    const apiUrl = 'http://47.96.180.35:7030'
    // const apiUrl = 'http://localhost:8080'
    const token = localStorage.getItem('token') || '2BEAD3534EF3896C8EF4D2873DBAB71A'; // token
    const CancelToken = axios.CancelToken;
    const apiCancel = CancelToken.source();
    let timeout_t;
    let {
        url = '', // 请求路径
        method = 'GET',  // method
        showToast = true, // 是否显示toast
        hasNull = false, // 是否过滤请求参数空值字段
        data = {}, // 请求参数
        timeout // 超时时间
    } = opt;
    method = method.toUpperCase();
    let query = '';

    if (method === 'GET') { // query
        query = transQuery(opt.data, hasNull);
        query = query ? `?${query}` : '';
    } else { // form data
        data = opt.data || {};
    }
    if (!url) {
        console.error('请求地址错误~');
        Toast('请求地址错误~');
        return;
    }
    let header = {
        'content-type': 'application/json',
        clientFromCode: 'H5X'
    };
    if (token) {
        header['MS-TOKEN'] = `TOKEN=${token}`;
    }
    let apiTime = +new Date(); // 请求开始时间
    // 请求超时
    if (timeout && timeout > 1) {
        timeout = timeout < 1500 ? 1500 : timeout;
        timeout_t = setTimeout(() => {
            apiCancel.cancel();
        }, timeout);
    }
    
    return axios({
        url: apiUrl + url + query,
        headers: {...header,...opt.header},
        data: data,
        method: method,
    }).then(res => {
        const resData = res.data;
        const code = resData.code;
        if (code === 200) {
            // 接口请求成功
            return resData.data;
            // opt.success && opt.success(resData.data);
        } else {
            // 接口错误码处理
            let errMsg = '';
            switch(code) {
                case 40020:
                    errMsg = '参数异常';
                    break;
                case 40130:
                    errMsg = '验证码失效';
                    break;
                case 5000:
                    errMsg = '服务器异常';
                    break;
                default:
                    errMsg = '接口错误';
            }
            if (code !== 4001 && showToast) {
                Toast(errMsg);
            };
            // 错误方便查看调试
            console.log({
                msg: '报错信息',
                req: {
                    url: url,
                    query: query,
                    data: data,
                },
                res: res
            });
            // opt.fail && opt.fail(resData);
            return resData;
        }
    }).catch(err => {
        if (err && err.errMsg) {
            console.log(err.errMsg);
        }
        // opt.fail && opt.fail(err);
        return err;
    }).finally(res => {
        clearTimeout(timeout_t); // 清除请求超时定时器
        if (process.env.NODE_ENV !== 'production') {
            console.info(`${url}请求时长:`, (+new Date() - apiTime) + 'ms'); // 请求时长
        }
    });
};

export default ajaxGateWay;