<template>
  <div class="wrapper">
    <div class="faq-cells">
      <div
        v-for="(item, index) in faqList"
        :key="`fqa_${index}`"
        class="faq-cell"
        @click="toggleFaq(index)"
      >
        <div class="title">{{ index + 1 }}.{{ item.title }}</div>
        <div :class="['content', item.show ? 'show' : '']">
          <div
            v-for="content in item.content"
            :key="content"
            wx:for-item="content"
            class="p"
          >
            {{ content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FQA",
  components: {},
  data() {
    return {
      faqList: [
        {
          title: "同一账号多设备登陆问题",
          content: ["一个账号只能在一台设备上登录，不能多台设备同时登陆。"],
          show: false,
        },
        {
          title: "如何查看会员有效期？",
          content: [
            "1、在开通VIP模块可以查看所购买岗位的会员有效期",
            "2、也可以在订单中，查看所购买的会员有效期",
          ],
          show: false,
        },
        {
          title: "附件为什么不能直接查看？",
          content: [
            "目前还不支持在线直接查看的功能，可以直接分享到微信PC端，另存为文件，可以在电脑端使用",
          ],
          show: false,
        },
        {
          title: "附件怎么下载？",
          content: [
            "点击附件对应的下载按钮，把附件文件分享到微信后，在微信PC端另存为文件即可",
          ],
          show: false,
        },
        {
          title: "为什么只能看5个课程",
          content: [
            "每个用户在每个岗位上，是可以免费试看5个课程，如果您想要看更多的课程，可以开通该岗位的VIP会员",
          ],
          show: false,
        },
        {
          title: "购买了会员，有些内容怎么看不了？",
          content: [
            "您购买了某个岗位的会员，那么这个岗位对应的全部内容都可以观看了，但是其他岗位的内容没法观看，需要开通其他岗位的会员才可以观看",
          ],
          show: false,
        },
        {
          title: "如何退款",
          content: ["请在购买前慎重考虑，会员购买后不支持退款"],
          show: false,
        },
        {
          title: "怎么成为老师？",
          content: [
            "茅生对老师的行业资历有严格的要求，您可以在申请老师处申请，如果符合茅生的要求，会有工作人员与您取得联系",
          ],
          show: false,
        },
        {
          title: "怎么开具发票？",
          content: ["暂时还不支持开具发票，我们正在紧张开发中，请耐心等待"],
          show: false,
        },
        {
          title: "客服咨询什么？",
          content: [
            "您可以咨询招聘过程中遇到的一些问题，也可以咨询APP的使用，购买等相关问题",
          ],
          show: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // 展开问题
    toggleFaq(index) {
      let { faqList } = this;
      faqList = faqList.map((item, index_faq) => {
        item.show = index_faq === index;
        return item;
      });
      this.faqList = faqList;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>