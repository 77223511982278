const formatTime = (date, type = '', split = '/') => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    if (type === 'date') {
        return [year, month, day].map(formatNumber).join(split)
    } else if (type === 'time') {
        return [hour, minute, second].map(formatNumber).join(':')
    } else {
        return [year, month, day].map(formatNumber).join(split) + ' ' + [hour, minute, second].map(formatNumber).join(':')
    }
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}


/* query转换 */
const transQuery = (obj, hasNull = false) => {
    let query = '';
    let queryArr = [];
    for (let key in obj) {
        if (typeof obj[key] !== 'object') {
            let item = String(obj[key]) || hasNull ? `${key}=${encodeURIComponent(obj[key])}` : '';
            if (item) {
                queryArr.push(item);
            }
        }
    }

    query = queryArr.sort().join('&');
    return query;
};

/* query转换json */
const query2json = query => {
    query = String(query);
    let queryArr = query.split('&');
    let obj = {};
    if (queryArr && queryArr.length > 0) {
        queryArr.forEach(item => {
            let arr = item.split('=');
            obj[arr[0]] = arr[1];
        });
    }
    return obj;
};

module.exports = {
    formatTime,
    transQuery,
    query2json
}