<template>
  <div class="wrapper">
    <div class="line-cells">
      <!-- <a
                class="line-cell"
                :href="`tel:${phone}`"
            >
                <div class="label">
                    客服电话
                </div>
                <div class="content">
                    {{phone}}
                    <span class="icon-phone"></span>
                </div>
            </a> -->
      <div class="line-cell" @click="copyWeixin">
        <div class="top">
          <div class="label">茅生客服，请咨询：</div>

          <!-- <div class="content">
            {{ weixin }}
            <img
              class="icon-copy"
              src="https://7072-product-v0c74-1259148926.tcb.qcloud.la/icon/icon-copy.png"
            />
          </div> -->
        </div>
        <img
          src="https://msdx-public.oss-cn-hangzhou.aliyuncs.com/QR.jpg"
          class="QRImg"
        />
        <div class="bottom">
          <div class="p"></div>

          <div class="p">工作时间：9:00-21:00（工作日）</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {},
  data() {
    return {
      phone: "13444445555",
      weixin: "茅生大学",
    };
  },
  mounted() {},
  methods: {
    // 复制微信
    copyWeixin() {
      let { weixin } = this;
      if (weixin) {
        navigator.clipboard.writeText(weixin);
        this.$toast("复制成功");
      }
    },
  },
};
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>