<template>
    <div class="wrapper">
        <h3>跳转中</h3>
    </div>
</template>

<script>
export default {
    name: 'Schema',
    components: {
        
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>