import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Index.vue'
import Detail from '../views/Detail/Index.vue'
import None from '../views/None/Index.vue'
import Schema from '../views/Schema/Index.vue'
import FAQ from '../views/FAQ/Index.vue'
import Contact from '../views/Contact/Index.vue'
import Protocol from '../views/Protocol/Index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/detail',
        name: 'Detail',
        component: Detail
    },
    {
        path: '/schema',
        name: 'Schema',
        component: Schema
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: Protocol
    },
    {
        path: '*',
        name: 'None',
        component: None
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
