import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ajaxGateWay from './utils/ajaxGateWay'
import { Toast } from 'vant'

Vue.config.productionTip = false;
Vue.prototype.$ajaxGateWay = ajaxGateWay;

Vue.use(Toast);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
