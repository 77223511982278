<template>
    <div class="container">
        <div class="info">
            <div class="course">
                <div class="name">
                    {{intro.content}}
                </div>
                <div class="tag">
                    {{tag}}
                </div>
            </div>
            <div class="author">
                <div class="avatar">
                    <img
                        v-if="userInfo.headUrl"
                        :src="userInfo.headUrl "
                        alt="头像"
                    />
                </div>
                <div class="author-info">
                    <div class="nick-name">
                        {{userInfo.nickname}}
                    </div>
                    <div
                        v-if="userInfo.whatMyself"
                        class="desc"
                    >
                        {{userInfo.whatMyself}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="box">
            <div class="title">
                白板
            </div>
            <div class="content">
                我是白板文字规范我是白板文字规范，我是白板文字规范，我是白板文字规范我是白板文字规范，我是白板文字规范，我是白板文字规范我是白板文字规范，我是白板文字规范，我是白板文字规范我是白板文字规范，我是白板文字规范，我是搜索白板文字规范我是白板文字规范，我是白板文字规范，
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Detail-Intro',
    props: ['intro'],
    computed: {
        tag: function() {
            let { intro } = this;
            let tags = intro.courseTags || [];
            let str = tags.map(item => item.content).join(' | ');
            return str;
        },
        userInfo: function() {
            let { intro } = this;
            return intro.courseInfoUserResponseDTO || {};
        }
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>