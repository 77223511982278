<template>
    <div class="wrapper">
        <h2>404</h2>
    </div>
</template>

<script>
export default {
    name: 'None',
    components: {
        
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>