<template>
    <div class="wrapper">
        <div class="banner">
            <video
                v-if="courseFileUrl"
                autoplay
                :src="courseFileUrl"
                @error="onVideoError"
            ></video>
            <img
                v-else-if="intro.coverUrl"
                :src="intro.coverUrl"
                alt="banner"
            />
        </div>
        <div
            class="tab-cells"
            :class="currentTab"
        >
            <div
                v-for="item in tabs"
                :key="item.type"
                class="tab-cell"
                @click="changeTab(item.type)"
            >
                <div
                    class="text"
                    :class="item.type === currentTab ? 'active' : ''"
                >
                    {{item.name}}
                </div>
            </div>
        </div>
        <van-swipe
            class="body"
            ref="bodySwiper"
            :class="currentTab"
            :autoplay="0"
            :duration="300"
            :loop="false"
            :show-indicators="false"
            @change="onChangeSwiper"
        >
            <van-swipe-item>
                <Intro
                    :intro="intro"
                />
            </van-swipe-item>
            <van-swipe-item>
                <Menu
                    :menu="menuList"
                    :playCourse="changePlayCourse"
                />
            </van-swipe-item>
        </van-swipe>
        <div
            class="btn-app"
            @click="openApp"
        >
            打开APP查看
        </div>
        <div
            v-if="showModal"
            class="modal"
        >
            <div class="mask"></div>
            <div class="box">
                <div class="icon-close"></div>
                <div class="title">
                    温馨提示
                </div>
                <div class="content">
                    5个免费课程观看结束啦，想要观看更多视频，快点击下方按钮下载APP吧，更多精彩课程在等你哦～
                </div>
                <div class="btn-download">
                    立即下载
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import Intro from './components/Intro/Index';
import Menu from './components/Menu/Index';

export default {
    name: 'Detail',
    components: {
        Intro,
        Menu,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data() {
        return {
            tabs: [
                {
                    type: 'intro',
                    name: '简介'
                },
                {
                    type: 'menu',
                    name: '目录'
                }
            ],
            currentTab: 'intro',
            menuList: [],
            intro: {},
            courseFileUrl: '',
            showModal: false
        }
    },
    mounted() {
        let { id } = this.$route.query;
        
        this.getDetailData(id);
    },
    methods: {
        // 获取课程详情
        async getDetailData(id) {
            if (id) {
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0
                });
                let res = await Promise.all([
                    this.getIntroData(id),
                    this.getMenuData(id)
                ]);
                let intro = res[0] || {},
                    menuList = res[1] || [];
                this.intro = intro;
                this.menuList = menuList;
                this.$toast.clear();
            }
        },
        // 获取课程简介
        getIntroData(id) {
            return this.$ajaxGateWay({
                url: `/msdx/course/info/description`,
                method: 'POST',
                data: {
                    id: Number(id)
                }
            });
        },
        // 获取课程目录
        getMenuData(id) {
            return this.$ajaxGateWay({
                url: `/msdx/course/info/catalog`,
                method: 'POST',
                data: {
                    id: Number(id)
                }
            }).then(res => {
                return res.cataLogs || [];
            });
        },
        // 切换tab
        changeTab(tab) {
            this.currentTab = tab;
            let index = 0;
            if (tab === 'menu') {
                index = 1;
            } else {
                index = 0;
            }
            this.$refs.bodySwiper.swipeTo(index);
        },
        // 监听轮播
        onChangeSwiper(e) {
            let tab = '';
            if (e === 1) {
                tab = 'menu';
            } else {
                tab = 'intro';
            }
            this.currentTab = tab;
        },
        // 切换课程播放
        changePlayCourse(url) {
            if (url) {
                this.courseFileUrl = url;
            }
        },
        // 监听视频播放错误
        onVideoError(e) {
            this.$toast('视频播放失败，请重试~');
            this.courseFileUrl = '';
        },
        // 打开APP
        openApp() {
            let system = window.navigator;
            let { appVersion } = system;
            if (appVersion.search(/Android/ig) > -1) {
                window.location.href = 'msdx://';
            } else if (appVersion.search(/iPhone/ig) > -1) {
                window.top.location.href = '/schema';
            }
        }
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>