<template>
    <div class="wrapper">
        <div
            v-if="type === 'service'"
            class="container"
        >
            <div class="title">用户使用服务协议</div>
            <div class="p">协议版本：MSZC-V2021-1</div>
            <div class="p">一、总则</div>
            <div class="p">1.1茅生（www.maosheng.vip）是由苏州食里路智能科技有限公司开发及运营的网格化、系统化的思维和技能训练平台，茅生APP、茅生PC网页、茅生微信小程序、茅生微信公众号等皆为茅生客户端（统称“茅生”）。</div>
            <div class="p">1.2请您在注册及使用茅生之前，仔细阅读并确认您已完全了解本协议的全部条款，特别是黑体加粗的内容您更需审慎阅读，如果您勾选了“同意”即表示完全接受本协议全部条款，本协议将构成您与茅生之间有约束力的法律文件。如果您不同意本协议的任何内容，请您停止注册和使用茅生服务。</div>
            <div class="p">1.3如您未满18周岁，请在法定监护人的监护、指导下阅读本协议和使用本服务。否则，请您不要接受本协议以及进行相关操作。</div>
            <div class="p">二、用户注册</div>
            <div class="p">2.1您在注册账号时，应确保所提交的身份信息的真实性、准确性及完整性；如果身份信息发生变化，您应及时更改。在您完成账号注册后，您应妥善保管账号及其密码，并对任何人利用您的帐号及密码所进行的活动负完全的责任。</div>
            <div class="p">2.2对恶意注册账号，或利用茅生账号进行违法活动、欺骗以及其他违反本协议的行为，茅生有权冻结、注销、删除其账号。</div>
            <div class="p">2.3在注册茅生账号时，您应：</div>
            <div class="p">（1）不使用色情、暴力或侮辱、诽谤他人等违反法律和公序良俗的词语注册帐号；</div>
            <div class="p">（2）不故意冒用他人信息及未经他人合法授权以他人名义为您注册帐号；</div>
            <div class="p">（3）不会利用茅生进行非法活动、或进行侵犯他人权利或损害他人利益的活动；不会以非法方式获取或利用茅生其他用户的信息；</div>
            <div class="p">（4）不会以技术方式攻击或破坏或改变茅生、或干扰其运行；不会以非法方式获取或使用茅生的任何软件、代码或其他技术或商业信息；不会对茅生运行的任何程序进行反向工程、反向编译、反向汇编或改写；</div>
            <div class="p">（5）不会未经许可使用茅生的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由茅生享有知识产权或权利的信息或资料；不会侵犯茅生的商标权、著作权、专利权、其他知识产权或其他合法权利或权益。</div>
            <div class="p">三、 用户行为</div>
            <div class="p">3.1用户可以在茅生发表评论，但是应遵守以下义务：</div>
            <div class="p">（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</div>
            <div class="p">（2）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；</div>
            <div class="p">（3）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</div>
            <div class="p">（4）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</div>
            <div class="p">（5）不得教唆他人从事本条所禁止的行为；</div>
            <div class="p">（6）不得利用在本平台注册的账户牟取非法利益，包括未经同意，在本平台以任何形式发布商业广告及宣传。</div>
            <div class="p">若用户未遵守以上规定的，本平台将有权不经过通知单方面采取限制使用、冻结账号、删除内容等措施。用户须对自己的行为承担法律责任。</div>
            <div class="p">四、服务收费</div>
            <div class="p">4.1 您充分了解并同意，茅生的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。茅生会在相关页面上做出提示，如果您拒绝支付该等费用，则不能使用相关的服务。</div>
            <div class="p">4.2 您充分了解并同意，茅生有权自行决定并修订相关的收费标准和规则，该类标准及规则一经发布即生效，并构成本协议的有效组成部分。茅生有权决定茅生所提供的服务的资费标准和收费方式，茅生可能会就不同的服务制定不同的资费标准和收费方式，也可能按照茅生所提供的服务的不同阶段确定不同的资费标准和收费方式。 </div>
            <div class="p">4.3茅生可能会根据实际需要对收费服务的收费标准、方式进行修改和变更，茅生也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，茅生会在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。您继续使用相应服务即视为您同意相关内容，茅生无需给予任何形式的补偿和退费。</div>
            <div class="p">五、侵权投诉</div>
            <div class="p">5.1 按照相关法律规定规定，任何第三方认为，用户利用茅生平台侵害其民事权益或实施侵权行为的，被侵权人有权书面通知茅生采取删除、屏蔽、断开链接等必要措施。</div>
            <div class="p">5.2 根据《信息网络传播权保护条例》的规定，任何第三方认为，茅生所涉及的作品、表演、录音录像制品，侵犯自己的合法权益的，可以向茅生提交投诉材料，要求茅生删除该侵权作品，或者断开链接。投诉材料中应当包含下列内容：权利人的姓名（名称）、联系方式和地址；要求删除或者断开链接的侵权作品、表演、录音录像制品的名称和网络地址；权利人的有效权利证明材料。权利人应当对投诉的真实性负责，如果侵权投诉不实，则需承担相应的法律责任。</div>
            <div class="p">六、法律责任</div>
            <div class="p">6.1 用户同意在茅生的言行所存在的风险将完全由其自己承担；因使用本网站产生的一切后果也由其自己承担。茅生不对用户的言论与链接负责，并不对由此引起的任何纠纷与法律诉讼等赔偿、损失和费用负责；</div>
            <div class="p">6.2 茅生不保证网站服务（包括硬件）一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。用户承诺不因茅生更改、更新或提高其服务给您造成的损失或不便而向茅生要求赔偿或补偿。</div>
            <div class="p">6.3 您了解并同意，茅生可能存在由第三方享有权利的内容；您不会违反法律而传播任何您不明确享有合法权利的内容。茅生可以随时依其自身判断指定您不应传播的第三方内容。茅生不保证您接受的任何第三方内容具有合法性或真实性或准确性或安全性，也不保证其构成任何可靠的或全面的知识系统，也不对因存储或传送第三方内容过程中产生的延误、错误、遗漏、不准确、或由此产生的任何直接或间接损害，向您赔偿或补偿或承担法律责任。</div>
            <div class="p">6.4 用户同意保障和维护茅生其它用户的利益，不会利用茅生进行非法活动、或进行侵犯他人权利或损害他人利益的活动；如因用户违反有关法律、法规或本协议项下的任何条款而给茅生或任何其它第三人造成损失，用户同意承担由此造成的损害赔偿责任。</div>
            <div class="p">6.5 您了解并同意，因版权原因需调整、更改或者下线有关作品内容，茅生有权根据实际情况，在不通知用户的情况下中断或终止向用户提供的一项或多项作品内容，对于因为前述原因或其他原因造成用户无法获取所需作品内容，茅生不承担任何责任且无需给予任何形式的补偿和退费。</div>
            <div class="p">七、协议修改</div>
            <div class="p">7.1茅生有权根据实际情况对本协议进行修订，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向用户告知修改详情。如果您继续使用本服务，即视为您已接受修改后的相关内容。如果您不接受修改后的相关内容，应当停止使用相关服务。</div>
            <div class="p">八、终止服务</div>
            <div class="p">8.1 茅生有权根据实际情况在任何时候，基于任何原因，暂时或永久地终止用户的账号、密码使用本网站服务，或删除、转移用户存储、发布在本网站的内容，而无需提前通知，且对用户和任意第三人均无需承担任何责任。 </div>
            <div class="p">8.2 终止用户服务后，用户使用服务的权利随即中止，用户没有权利，茅生也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。您同意，不因本协议或本协议项下茅生所提供的服务中止或终止，而要求茅生网向您作出赔偿或补偿或承担任何其他责任。</div>
            <div class="p">九、其他 </div>
            <div class="p">9.1 本协议签订地为苏州市。</div>
            <div class="p">9.2 本协议的生效、履行、解释及争议的解决，均适用中华人民共和国法律。因本协议解释或执行引起的任何争议，双方应友好协商解决；协商不成时，可将纠纷或争议提交至本协议签订地有管辖权的人民法院裁判。</div>
            <div class="p">9.3 您同意，茅生或司法、行政、检察、仲裁等机构向您发出的任何通知，可通过您在注册或使用本服务时所提供的手机号码、微信、地址、电子邮箱中的任何一种方式，以任何形式向您发出有关通知信息。茅生也可以通过平台内或站内的系统信息或私信的形式向您发送有关通知信息。前述通知信息一旦发出即刻生效。</div>
            <div class="p">9.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
            <div class="p">9.5本协议最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
        <div
            v-if="type === 'privacy'"
            class="container"
        >
            <div class="title">信息保护与隐私政策协议</div>
            <div class="p">协议版本：MSYS-V2021-1</div>
            <div class="p">协议发布日期：2022年04月12日</div>
            <div class="p">协议更新日期：2022年04月16日</div>
            <div class="p">协议生效日期：2022年04月16日</div>
            <div class="p">一、总则</div>
            <div class="p">1.1请您在注册及使用茅生服务前，审慎阅读并充分理解本协议，特别是黑体加粗的内容，当您勾选“同意”时，则表示您完全理解并同意接受本协议全部内容。如果您不同意本协议的任何内容，您应立即停止注册及使用茅生服务。
茅生是一款由苏州食里路智能科技有限公司（注册地为苏州市南环东路935号501室，用户信息保护人联系方式为13276367607）提供知识分享服务的产品。</div>
            <div class="p">二、 信息收集</div>
            <div class="p">2.1为帮助您完成注册及使用相关服务，茅生会向您收集如下必要信息：</div>
            <div class="p">（1）您在注册茅生平台帐号时向茅生提供的<strong>手机号码</strong>或<strong>电子邮箱</strong>、设置的<strong>账户密码</strong>。</div>
            <div class="p">（2）为向您提供特定产品和服务，个人用户可能需要向茅生提供<strong>真实姓名、身份证号码、银行卡、电话号码、联系地址、电子邮箱、头像和简介</strong>等信息，企业用户可能需要向茅生提供<strong>企业名称、营业执照、法定代表人身份信息、银行卡、电话号码、联系地址、电子邮箱、商标、简介</strong>等信息。如果您不使用特定产品和服务，则无需提供相关信息。</div>
            <div class="p">2.2您在使用茅生时，根据您的行为和产品特点，茅生将基于以下目的访问您的个人信息及手机上的以下必要内容，开通相应权限：获取茅生内容信息而访问<strong>网络</strong>；录制音频而获取用户<strong>麦克风</strong>；上传头像时使用用户<strong>相册</strong>；录制或上传视频时获取用户的<strong>摄像头或相册、视频</strong>；获取服务或产品更新提醒使用通知；使用本地化服务或线下服务而获取用户<strong>定位信息</strong>。 </div>
            <div class="p">2.3为更好的为您提供服务，茅生可能会记录您在使用服务时提供、形成或留存的信息，包括但不限于<strong>设备信息、日志信息、位置信息、评论信息、唯一应用程序编号、网络和连接信息、Cookie数据、访问数据、播放记录、消费记录、收藏记录</strong>等。 </div>
            <div class="p">2.4若您通过第三方登入茅生的，茅生将通过第三方获取您的昵称和头像，其他的信息以您授权第三方共享的为准。</div>
            <div class="p">2.5为确保网站正常运转、为您获得更轻松的访问体验、向您提供更好的服务，茅生会在您的计算机或移动设备上存储Cookie、Flash Cookie或浏览器或关联应用程序提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。</div>
            <div class="p">三、信息使用</div>
            <div class="p">3.1除下列情形外，茅生不会与任何其他第三方共享您的个人信息：</div>
            <div class="p">（1） 在获得您的授权或明确同意后，茅生才会与其他公司、组织和个人分享您的个人信息。只有您选择同意，茅生才会共享您的个人敏感信息。获得您明确同意或基于您的主动选择，茅生才可能会公开披露您的个人信息。</div>
            <div class="p">（2）茅生可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</div>
            <div class="p">（3）茅生可能委托授权合作方向您提供某些服务或代表茅生履行相关职责，出于此类正当理由，合作方可能使用您的信息，但授权合作方只能接触到为其履行职责所需信息，且不得将此信息用于其他任何目的。</div>
            <div class="p">（4）为促成纠纷的解决，茅生可能向涉及纠纷的第三方提供您的信息，但所提供的信息将严格受限于解决纠纷之目的，同时茅生也会约束有关第三方依法合规使用您的信息。</div>
            <div class="p">3.2为优化产品或服务，茅生将使用您提供的信息，自行或授权数据服务商进行数据统计、分析，以评估、维护，和改进茅生平台、产品或服务的性能，进行必要的业务运营，参与有关平台和服务的市场调研活动，开发新的平台、产品或服务，提供客户支持，进行茅生产品的市场推广和宣传。</div>
            <div class="p">3.3当茅生发生重组、合并、分立、清算或资产出售时，您的信息将作为交易标的转移到新的主体，在本协议变更前，您的个人信息仍然受到本协议的保护。</div>
            <div class="p">四、 信息管理</div>
            <div class="p">4.1您可以访问、更正及删除自己的账户信息或使用茅生的服务时您提供的其他个人信息。</div>
            <div class="p">4.2您可以自行注销账户，无法自行注销帐号的，可以联系茅生客服，协助您完成注销账户操作。</div>
            <div class="p">4.3如您发现收到了不属于您主动搜索、浏览的内容和作品、服务信息推送。如您不希望接收上述信息，可通过原路径进行退订，例如短信推送可以直接回复相关的退订口令。 </div>
            <div class="p">五、信息存储</div>
            <div class="p">5.1茅生在全球运营平台及服务时就收集到的信息将统一默认存储在中华人民共和国境内的服务器内。 </div>
            <div class="p">六、信息保护</div>
            <div class="p">6.1茅生将采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，包括但不限于运用各种安全技术和程序建立完善的管理制度、采用专业加密存储和传输方式等，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。 </div>
            <div class="p">6.2账户的注销</div>
            <div class="p">
                <div class="p-sub">6.2.1注销方式：我的->设置->账号管理->账号注销</div>
                <div class="p-sub">6.2.2 您注销账户后，您的个人信息在茅生前台将不可检索、访问。您知晓并理解，我们将按照《中华人民共和国网络安全法》等法律法规的规定留存你的相关信息；超出必要保存期限后，我们将删除或匿名化处理你的个人信息</div>
                
            </div>
            <div class="p">6.3合作伙伴SDK或相关技术获取用户信息情况披露</div>
            <div class="p">
                <div class="p-sub">6.3.1 SDK名称：友盟+SDK<br/>
                        服务类型：如统计分析 ,社会化分享,应用性能监控平台U-APM<br/>
                        个人信息收集范围：应用列表、设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/IP地址/SIM 卡 IMSI /位置/ICCID/设备序列号）<br/>
                        个人信息收集目的:  用于唯一标识设备，以便提供设备应用性能监控服务；通过采集位置信息提供反作弊服务，剔除作弊设备，排查应用性能崩溃原因。<br/>
                        个人信息收集方式: sdk本机采集<br/>
                        隐私权政策链接：https://www.umeng.com/page/policy</div>
                
                <div class="p-sub">
                        6.3.2 SDK名称：支付宝SDK<br/>
                        服务类型：应用移动支付<br/>
                        合作方：蚂蚁金服网络技术有限公司<br/>
                        个人信息收集范围：设备标识信息（IMSI、IMEI、Android ID、MAC地址、BSSID、SSID）,系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置<br/>
                        个人信息收集目的:  用于App 运行、网络请求、账号注册及登录等安全防护<br/>
                        个人信息收集方式: sdk本机采集<br/>
                        隐私政策：https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132
                </div>

                <div class="p-sub">6.3.3 SDK名称：微信openSDK<br/>
                        服务类型：嵌入第三方SDK，SDK收集传输个人信息<br/>
                        合作方：腾讯科技（深圳）有限公司<br/>
                        个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表<br/>
                        个人信息收集目的:  社交平台分享<br/>
                        个人信息收集方式: SDK本机采集<br/>
                        隐私政策：https://privacy.qq.com/policy/tencent-privacypolicy
                </div>

                <div class="p-sub">6.3.4. 阿里云号码认证SDK<br/>
                    服务类型：本机号码一键登录和认证<br/>
                    收集信息类型：网络类型、设备信息（含IP地址、设备制造商、设备型号、手机操作系统、SIM卡信息SIM State、SIM卡信息ICCID）<br/>
                    隐私政策链接: https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html
                </div>

                <div class="p-sub">6.3.5.小米推送SDK<br/>
                    使用目的:用于向您推送消息<br/>
                    信息获取类型:设备标识(OAID、加密的Android ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置<br/>
                    隐私政策链接:https://dev.mi.com/console/doc/detail?pId=1822
                </div>

                <div class="p-sub">6.3.6.OPUSH SDK<br/>
                    使用目的：推送通知栏消息<br/>
                    使用方式：去标识，加密传输的安全方式<br/>
                    收集数据类型：设备信息、应用信息、网络信息<br/>
                    隐私政策链接:https://open.oppomobile.com/new/developmentDoc/info?id=10288
                </div>
            
            <div class="p">七、协议修订</div>
            <div class="p">7.1茅生可能对本协议进行不定期修改，协议修改后，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向用户告知修改详情。如果您不同意修改后的协议，请立即停止使用茅生的服务；如果您在协议修改后继续使用茅生服务，则视为您已接受本协议的修改。 </div>
            <div class="p">其他</div>
            <div class="p">8.1本协议签订地为苏州市。</div>
            <div class="p">8.2与本协议的订立、解释、执行等相关的一切争议或纠纷，应友好协商，协商不成的，可将争议提交协议签订地有管辖权的人民法院裁判。</div>
            <div class="p">8.3本协议的最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
        <div
            v-if="type === 'creator'"
            class="container"
        >
            <div class="title">创作者服务协议</div>
            <div class="p">协议版本：MSCZ-V2021-1</div>
            <div class="p">总则</div>
            <div class="p">1.1如您希望在茅生发布音频或视频等作品，获得“创作者”身份，请您务必审慎阅读、充分理解各条款内容，特别是黑体加粗条款内容。除非您已阅读并接受本协议所有条款，否则您无权使用茅生提供的发布作品服务。当您勾选“同意”时，即视为您已阅读并完全同意本协议内容。如果您不同意本协议的任何内容，请您停止申请开通“创作者”操作，或者停止使用与“创作者”相关的服务。</div>
            <div class="p">1.2 应法律和平台治理要求，当您注册成为茅生“创作者”后，您应当按照要求进行实名认证操作，个人用户应当向茅生提供真实姓名、身份证及其信息等，企业用户应当向茅生提供企业名称、营业执照、法定代表人身份证及信息等。如果您不同意实名认证，您将无法使用茅生的作品发布服务。</div>
            <div class="p">二、作品要求</div>
            <div class="p">2.1用户在茅生发布作品时，必须遵守国家有关法律规定，并承担一切因自己发布信息不当导致的民事、行政或刑事法律责任。用户在茅生发布的作品，不得含有以下内容：</div>
            <div class="p">（1）违反宪法确定的基本原则和内容的； （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（3）损害国家荣誉和利益，攻击党和政府的；（4）煽动民族仇恨、民族歧视，破坏民族团结的； （5）破坏国家、地区间友好关系的；（6）违背中华民族传统美德、社会公德、论理道德、以及社会主义精神文明的； （7）破坏国家宗教政策，宣扬邪教和封建迷信的；（8）散布谣言或不实消息，扰乱社会秩序 ，破坏社会稳定的；</div>
            <div class="p">（9）煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；（10）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
            <div class="p">（11）侮辱或诽谤他人，侵害他人合法权益的；（12）侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；（13）侵犯他人知识产权、商业秘密、技术秘密的；                             </div>
            <div class="p">（14）使用漫骂、辱骂、中伤、恐吓、诅咒等不文明语言的；（15）以非法民间组织名义活动的； （16）其他法律、法规禁止的内容。</div>
            <div class="p">2.2若用户拟发布的作品及其内容不符合2.1要求，茅生有权拒绝发布相关作品。已经发布的相关违规作品，茅生可不经过通知该用户直接下架、删除相关作品。经茅生评估，认为用户存在重大违规风险的，茅生有权不经过通知该用户，直接对相关账户采取限制使用、冻结等措施。</div>
            <div class="p">版权约定</div>
            <div class="p">3.1用户承诺，所发布的作品属于自己的原创作品，或经相关权利人授权许可的作品，来源真实、合法，尚未在其他渠道首次发表，不存在侵犯他人权益的情况。</div>
            <div class="p">3.2用户在茅生发布作品后（即首次发表后），与该作品相关的全部财产性权利（包括不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等）一次性转让给茅生，茅生将在全球、全互联网、永久性对作品享有前述权利，作为回报，茅生将根据该作品的商业运作盈利结果持续向用户支付转让报酬（见本协议第四条“转让报酬”约定，本协议的“转让报酬”或称“创作收入”）。3.3茅生基于受让与作品相关的全部财产性权利，拥有作品的独家经营权和商业上的处置权，并将前述权利转授给茅生合作方的权利。包括不限于：茅生有权将作品用于本平台的商业运作，以及在其它渠道、平台推荐或传播、出租此作品，与其他第三方就作品的商业运作进行合作等。</div>
            <div class="p">3.4未经茅生的书面同意，用户及任何第三方无权行使本协议3.2中约定的已经转让给茅生全部权利中的任何权利。包括不限于：在其他音频/视频网站，发布或授权他人发布、传播、运作已经在茅生发布的作品，否则视为用户违约及侵权，茅生有权下架用户在茅生发布的相关作品或全部作品，并有权要求用户承担违约责任及侵权损失。</div>
            <div class="p">3.5未经茅生的书面同意，用户不得将茅生的作品以任何形式的复制、修改、翻译、改编、汇编等形式制作衍生作品，进行分发、公开展示或商业运作。</div>
            <div class="p">3.6未经茅生的同意，用户不应要求茅生对已发布作品进行删除、修改等不利于作品传播和运作的操作，否则视为用户违约，茅生有权要求违约用户承担其违约行为给茅生平台和其他用户造成的经济损失。</div>
            <div class="p">转让报酬（创作收入）</div>
            <div class="p">4.1茅生对用户消费作品的收费方式为会员制收费，即按年收取固定会员费用，全体付费用户的总付费额构成茅生的总销售收入额。按照当前的平台运营情况，茅生有权拿出总销售收入额的40%，作为茅生受让全部作品财产性权利而向创作者支付的报酬（茅生有权根据实际情况对转让报酬及其比例进行调整）。</div>
            <div class="p">4.2您的创作收入取决于付费用户使用您作品的时长（需去除同一用户的重复使用时间）占全体付费用户使用茅生全部作品时长的比例，比例越大，收入越高。</div>
            <div class="p">4.3茅生也可能通过一次性支付转让报酬，来买断一些作品的版权，这类作品的创作收入需另行订立协议，不适用本协议关于“创作收入”的约定。</div>
            <div class="p">4.4 您在茅生获得的创作收入为增值税含税收入，您有义务向茅生提供增值税发票，或者由茅生配合您向税务机关取得增值税发票；同时茅生会依据法律规定对您的个人所得完成代扣缴。</div>
            <div class="p">五、结算与支付</div>
            <div class="p">5.1茅生将每天对全部作品统一进行一次创作收入结算。</div>
            <div class="p">5.2茅生将按照本协议约定，在扣除您应缴纳的个人所得税后，将您的个人创作收入下发至您的茅生个人账户，您可以对该金额按月实施提现操作。您可以提现上个月，以及上个月更早的月份的收益。</div>
            <div class="p">六、费用扣除</div>
            <div class="p">6.1如果您违反《用户服务及隐私政策协议》或本协议或其他茅生的协议、规则，或侵害他人合法权益，且依法应当承担违约责任或赔偿损失时，茅生有权直接从您的创作收入直接扣除相关金额，并支付至守约方或遭受损失方（包括不限于茅生、其他用户、茅生合作方、被侵权方等）。</div>
            <div class="p">6.2当您的个人账户创作收入余额不足以支付违约金或赔偿金时，不足的部分您应当主动向有关方支付，以免发生法律追偿纠纷。若茅生提前为您垫付了相关违约金或赔偿款时，茅生有权直接从您的创作收入扣除相关金额，或者依法向您继续追偿。</div>
            <div class="p">七、版权保护</div>
            <div class="p">7.1为保障著作权人和/或依法可以行使信息网络传播权的权利人（以下称“权利人”）的合法权益，如权利人认为茅生所提供的信息存储空间、搜索或链接服务存储、搜索或链接的内容所涉及的作品侵犯了其信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可书面通知茅生，要求茅生删除该作品，或者断开该作品的链接。 茅生将积极核实，并根据核实结果，按照相关法律规定采取对应措施。您的通知应当具备下列内容：（1）权利人的姓名（名称）、联系方式、地址、身份证明复印件； （2）要求删除或者断开链接的侵权作品的准确名称和网络地址；（3）认为构成侵权的初步证明材料，包括但不限于权属证明、创作手稿、经权威机构签发的作品创作时间戳等。7.2茅生收到通知后，将会对其进行形式审查，审查通过后将依据《信息网络传播权保护条例》的规定将及时删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接，并同时将通知书转送作品提供者。如不符合上述条件，茅生会请您继续补足相应信息，且暂不采取包括删除等相应措施。7.3若茅生收到权利人通知，主张用户发布或传播的内容侵犯其相关权利的，茅生有权进行独立判断并采取删除、屏蔽或断开链接等措施。7.4因用户违反本协议而导致任何第三方及茅生遭受损失的，用户应一并赔偿各方损失。</div>
            <div class="p">8、协议修改8.1茅生有权根据实际情况的变化，及茅生业务发展需要，对本协议的条款作出变更或者修改，一旦本协议的内容发生变动，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向您告知修改详情。</div>
            <div class="p">8.2如果您不同意本协议相关条款的修改内容，您应当停止使用茅生中与“创作者”相关的服务，如果您继续使用茅生，则视为您接受茅生对本协议相关内容的所做的修改。</div>
            <div class="p">九、其他</div>
            <div class="p">9.1本协议签订于苏州市。</div>
            <div class="p">9.2 因本协议的订立、执行、解释等产生的纠纷，应友好协商，协商不成的，可以将纠纷或争议提交至本协议签订地有管辖权的人民法院进行裁判。</div>
            <div class="p">9.3本协议的最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Protocol',
    components: {
        
    },
    data() { 
        return {
            type: 'service'
        }
    },
    mounted() {
        let { type } = this.$route.query;
        if (type) {
            this.type = type;
        }
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>