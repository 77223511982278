<template>
    <div class="container">
        <div class="menu-cells">
            <div
                v-for="(item, index) in menu"
                :key="index"
                class="menu-cell"
                @click="selectCourse(index)"
            >
                第{{index + 1}}节 {{item.whiteboard}}
                <div
                    v-if="index === currenCourse"
                    class="icon-course"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Detail-Menu',
    props: ['menu', 'playCourse'],
    data() {
        return {
            currenCourse: -1
        }
    },
    methods: {
        // 选择课程
        selectCourse(index) {
            let { menu } = this;
            let fileUrl = menu[index].fileUrl;
            fileUrl = decodeURIComponent(fileUrl);
            this.playCourse(fileUrl);
        }
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>